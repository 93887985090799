<template>
  <OrderPriceTableAdmin
      :navTabs="navTabs"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :isOtherPrices="isOtherPrices"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @print="printBarcodes"
      @deleteOrder="(id) => deleteOrder(id, true)"
  />
</template>

<script>
  import OrderPriceTableAdmin from "./OrderPriceTableAdmin/OrderPriceTableAdmin";
  import {
    APC_CAMBRIDGE,
    DPD_SHIPPING_COMPANY,
    GLOBAL24_SHIPPING_COMPANY,
    ORDER_PRICE_TYPES,
    POSTNL_SHIPPING_COMPANY, ROYAL_MAIL_COMPANY
  } from "@/staticData/staticVariables";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "OrderPriceTable",
    components: {
      OrderPriceTableAdmin,
    },

    mixins: [routeFilter],

    computed: {
      isOtherPrices() {
        return this.navTabs[DPD_SHIPPING_COMPANY.name].active ||
            this.navTabs[POSTNL_SHIPPING_COMPANY.name].active ||
            this.navTabs[GLOBAL24_SHIPPING_COMPANY.name].active ||
            this.navTabs[APC_CAMBRIDGE.name].active ||
            this.navTabs[ROYAL_MAIL_COMPANY.name].active ||
            this.navTabs[ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name].active
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getOrderPriceForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterId: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          // all: {
          //   active: true,
          //   // label: this.$t('FBM_allOrders.localization_value.value'),
          //   label: 'fbm_allOrders',
          //   name: 'all',
          //   id: false,
          //   permissionVisible: true,
          // },
          [ORDER_PRICE_TYPES.ORDER_PRICE_FBM.name]: {
            active: true,
            label: 'orderPrice_fbm',
            name: ORDER_PRICE_TYPES.ORDER_PRICE_FBM.name,
            value: ORDER_PRICE_TYPES.ORDER_PRICE_FBM.value,
            // permissionVisible: false,
          },
          [ORDER_PRICE_TYPES.ORDER_PRICE_FBA.name]: {
            active: false,
            label: 'orderPrice_fba',
            name: ORDER_PRICE_TYPES.ORDER_PRICE_FBA.name,
            value: ORDER_PRICE_TYPES.ORDER_PRICE_FBA.value,
            // permissionVisible: false,
          },
          [ORDER_PRICE_TYPES.ORDER_PRICE_CREATE_LABEL.name]: {
            active: false,
            label: 'orderPrice_iCreateLabel',
            name: ORDER_PRICE_TYPES.ORDER_PRICE_CREATE_LABEL.name,
            value: ORDER_PRICE_TYPES.ORDER_PRICE_CREATE_LABEL.value,
            // permissionVisible: false,
          },
          [DPD_SHIPPING_COMPANY.name]: {
            active: false,
            label: 'orderPrice_dpd',
            name: DPD_SHIPPING_COMPANY.name,
            value: DPD_SHIPPING_COMPANY.deliveryServiceCountryPriceId,
            // permissionVisible: false,
          },
          [POSTNL_SHIPPING_COMPANY.name]: {
            active: false,
            label: 'orderPrice_postNL',
            name: POSTNL_SHIPPING_COMPANY.name,
            value: POSTNL_SHIPPING_COMPANY.deliveryServiceCountryPriceId,
            // permissionVisible: false,
          },
          [GLOBAL24_SHIPPING_COMPANY.name]: {
            active: false,
            label: 'orderPrice_global24',
            name: GLOBAL24_SHIPPING_COMPANY.name,
            value: GLOBAL24_SHIPPING_COMPANY.deliveryServiceCountryPriceId,
            // permissionVisible: false,
          },
          [APC_CAMBRIDGE.name]: {
            active: false,
            label: 'orderPrice_APCCambridge',
            name: APC_CAMBRIDGE.name,
            value: APC_CAMBRIDGE.deliveryServiceCountryPriceId,
            // permissionVisible: false,
          },
          [ROYAL_MAIL_COMPANY.name]: {
            active: false,
            label: 'orderPrice_RoyalMail',
            name: ROYAL_MAIL_COMPANY.name,
            value: ROYAL_MAIL_COMPANY.deliveryServiceCountryPriceId,
            // permissionVisible: false,
          },
          [ORDER_PRICE_TYPES.ORDER_PRICE_SKLADUSA_FEE.name]: {
            active: false,
            label: 'orderPrice_skladusaFee',
            name: ORDER_PRICE_TYPES.ORDER_PRICE_SKLADUSA_FEE.name,
            value: ORDER_PRICE_TYPES.ORDER_PRICE_SKLADUSA_FEE.value,
            // permissionVisible: false,
          },
          [ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name]: {
            active: false,
            label: 'orderPrice_zoneSkipping',
            name: ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name,
            value: ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.value,
            // permissionVisible: false,
          },
        },
      }
    },


    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs[ORDER_PRICE_TYPES.ORDER_PRICE_FBM.name].active = false
        this.navTabs[this.$route.query.type].active = true
      }

      this.filter()
    },

    methods: {


      deleteOrder() {

      },


      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )


        if (this.isOtherPrices) {
          // let type = this.navTabs[DPD_SHIPPING_COMPANY.name].value
          // if(this.filterType !== ''){
          //   let foundType = this._.find(this.navTabs, {active: true})
          //   type = foundType.value
          // }
          if(this.navTabs[ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name].active) {
            this.$store.commit('setOrderPriceDeliveryServices',{orderPriceDeliveryServices: []})
            this.$store.commit('setOrderPriceDeliveryServicesCommonList',{orderPriceDeliveryServicesCommonList: []})

          } else {
            if (next) this.$store.commit('setNextOrderPriceDeliveryServicesPage', true)
            this.$store.dispatch('fetchOrderPriceDeliveryServices', url).then(() => {
              this.$store.commit('setNextOrderPriceDeliveryServicesPage', false)
            })
          }




        } else {

          let type = 'fee_order_fbm'
          if(this.filterType !== ''){
            let foundType = this._.find(ORDER_PRICE_TYPES, {name: this.filterType})
            type = foundType.value
          }

          if (next) this.$store.commit('setNextOrderPricePage', true)
          this.$store.dispatch('fetchOrderPrice', {type: type, filter: url}).then(() => {
            this.$store.commit('setNextOrderPricePage', false)
          })
        }

        this.checkCountFilter([])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if (this.isOtherPrices) {
          let foundType = this._.find(this.navTabs, {active: true})
          let type = foundType.value

          myQuery.where('delivery_service_id', type)

          // this.generateFilterQueryParams(
          //     myQuery,
          //     {
          //       filterId: 'delivery_service_id',
          //     },
          // )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      printBarcodes() {
        let type = 'fee_order_fbm'
        if(this.$route.query.type && this.$route.query.type !== ''){
          let foundType = this._.find(ORDER_PRICE_TYPES, {name: this.$route.query.type})
          type = foundType.value
        }

       //  let routeData = this.$router.resolve({
       //    path: this.$store.getters.GET_PATHS.generatePdfFile + '/order-price-print',
       //    query: {
       //      type: type,
       //      format: 'pdf'
       //    }
       //  })
       //
       // window.open(routeData.href, '_blank');

        this.$store.dispatch('getOrderPriceFile', {type: type, format: 'docx'}).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, type + '.docx')
          //this.downloadBlobFile(response, 'docx')

          //this.openDownloadedFileInIframe(response)
        }).catch(error => this.createErrorLog(error))
      },
    },


  }
</script>

<style scoped>

</style>