<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <div class="table-card__item-number table-card__item-number--small">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('orderPrice_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('orderPrice_Country.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'country.currentTranslate.name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('orderPrice_Price.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'price'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('orderPrice_Weight.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              {{parseFloat(item.max_weight).toFixed(2)}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('orderPrice_FromCountry.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'from_country.currentTranslate.name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50"
               v-if="navTabs[POSTNL_SHIPPING_COMPANY.name].active"
          >
            <div class="table-card__item-label">
              {{$t('orderPrice_Zip.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'zip_mask'"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-btn pl-2">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                @click.native="$emit('changeOrderPriceDeliveryServicesPopup')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {POSTNL_SHIPPING_COMPANY} from "@/staticData/staticVariables";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
export default {
  name: "OrderPriceDeliveryServicesTableMobile",
  components: {LinkButton, ValueHelper},
  props: {
    item: Object,
    navTabs: Object,
  },

  data() {
    return {
      show: false,
      POSTNL_SHIPPING_COMPANY: POSTNL_SHIPPING_COMPANY,
    }
  },
  
}
</script>

<style scoped>

</style>