var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-left-block-btn btn-style",class:{ 'disable-click' : _vm.disabled }},[_c('div',{staticClass:"head-left-block-btn__txt",class:{
        'consolidation' : _vm.type === 'consolidation',
        'delete' : _vm.type === 'delete',
        'transfer' : _vm.type === 'transfer',
        'print' : _vm.type === 'print',
        'generate' : _vm.type === 'generate',
      }},[_vm._v(" "+_vm._s(_vm.value)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }