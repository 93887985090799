import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function OrderOtherPrice() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    price: '',
    useDefault: false,
    country: '',
    weight: '',
    fromCountry: '',
    zip: '',
    historyOfChanged: '',
  }

  this.validation = {
    id: false,
    price: false,
    useDefault: false,
    country: false,
    weight: false,
    fromCountry: false,
    zip: false,
  }

  this.validationTranslate = {
    id: '',
    price: '',
    useDefault: '',
    country: '',
    weight: '',
    fromCountry: '',
    zip: '',
  }

  this.validationTxt = {
    id: false,
    price: false,
    useDefault: false,
    country: false,
    weight: false,
    fromCountry: false,
    zip: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getUseDefault = () => {
    return this.data.useDefault
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getWeight = () => {
    return this.data.weight
  }
  this.getFromCountry = () => {
    return this.data.fromCountry
  }
  this.getZip = () => {
    return this.data.zip
  }
  this.getHistoryOfChanged = () => {
    return this.data.historyOfChanged
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setUseDefault = (val) => {
    this.data.useDefault = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
  this.setWeight = (val) => {
    this.data.weight = val
  }
  this.setFromCountry = (val) => {
    this.data.fromCountry = val
  }
  this.setZip = (val) => {
    this.data.zip = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
}

/**
 * Global Setters
 */

OrderOtherPrice.prototype.setItem = function (item) {

  this.setId(item?.id)
  this.setPrice(parseFloat(item?.price).toFixed(2))
  this.setUseDefault(item?.use_default)
  this.setCountry({id: item?.country.id, label: item?.country.name})
  this.setWeight(parseFloat(item?.max_weight).toFixed(2))
  this.setFromCountry({id: item?.from_country.id, label: item?.from_country.name})
  this.setZip(item?.zip_mask)
  this.setHistoryOfChanged(item?.activityUUID)
}

/**
 * Prepare Data
 */

OrderOtherPrice.prototype.prepareSave = function () {
  return {
    'price': this.getPrice(),
    // 'use_default': this.getUseDefault(),
    'country_id': this.getCountry()?.id,
    'max_weight': this.getWeight(),
    'from_country_id': this.getFromCountry()?.id,
    'price_currency_id': 4,
    'max_weight_measure_unit_id': 12,
    'zip_mask': this.getZip(),
  }
}


/**
 * Validations
 */

OrderOtherPrice.prototype.firstValidationUser = function () {

  let validationItems = {
    price: this.getPrice(),
    // useDefault: this.getUseDefault(),
    country: this.getCountry()?.id,
    fromCountry: this.getFromCountry()?.id,
    weight: this.getWeight(),
  }

  let validationOptions = {
    price: {type: ['not-zero','empty']},
    weight: {type: ['empty']},
    country: {type: ['empty']},
    fromCountry: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
