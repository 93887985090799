import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function ZonePrice() {

  this.checkValid = checkValid

  this.data = {
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
    deliveryService: '',
    currency: '',
    fromCountry: '',
    warehouse: '',
    // comment: '',
    // number: '',
  }

  this.validation = {
    file: false,
    deliveryService: false,
    currency: false,
    fromCountry: false,
    // comment: false,
    // number: false,
  }

  this.validationTranslate = {
    file: '',
    deliveryService: '',
    currency: '',
    fromCountry: '',
    // comment: '',
    // number: '',
  }

  this.validationTxt = {
    file: false,
    deliveryService: false,
    currency: false,
    fromCountry: false,
    // comment: false,
    // number: false,
  }

  /**
   * Getters
   */
  // this.getId = () => {
  //   return this.data.id
  // }
  this.getDeliveryService = () => {
    return this.data.deliveryService
  }
  this.getCurrency = () => {
    return this.data.currency
  }
  this.getFromCountry = () => {
    return this.data.fromCountry
  }
  this.getWarehouse = () => {
    return this.data.warehouse
  }
  // this.getMarketplace = () => {
  //   return this.data.marketplace
  // }
  // this.getComment = () => {
  //   return this.data.comment
  // }
  // this.getNumber = () => {
  //   return this.data.number
  // }

  /**
   * Setters
   */
  // this.setId = (val) => {
  //   this.data.id = val
  // }
  this.setDeliveryService = (val) => {
    this.data.deliveryService = val
  }
  this.setCurrency = (val) => {
    this.data.currency = val
  }
  this.setFromCountry = (val) => {
    this.data.fromCountry = val
  }
  this.setWarehouse = (val) => {
    this.data.warehouse = val
  }
  // this.setMarketplace = (val) => {
  //   this.data.marketplace = val
  // }
  // this.setComment = (val) => {
  //   this.data.comment = val
  // }
  // this.setNumber = (val) => {
  //   this.data.number = val
  // }
}

/**
 * Global Setters
 */

ZonePrice.prototype.setItem = function (item) {

  this.setDeliveryService(item?.id)
  this.setCurrency(parseFloat(item?.cost).toFixed(2))
  this.setFromCountry(parseFloat(item?.cost).toFixed(2))
  // this.setUseDefault(item?.use_default)
  // this.setMarketplace(item?.marketplace)
  // this.setComment(item?.comment)
  // this.setNumber(item?.number)
}

/**
 * Prepare Data
 */

ZonePrice.prototype.prepareSave = function () {
  let formDataArray = []
  let files = this.data.File.data.files

  files.map((item) => {
    let reader = new FormData()
    reader.append("import-file", item.file)
    reader.append("delivery_service_id", this.getDeliveryService()?.id)
    reader.append("from_country_id", this.getFromCountry()?.id)
    reader.append("currency_id", this.getCurrency()?.id)
    if(this.getWarehouse()?.id) {
      reader.append("warehouse_id", this.getWarehouse()?.id)
    }
    formDataArray.push(reader)
  })

  return formDataArray[0]

  // return {
  //   'delivery_service_id': this.getDeliveryService(),
  //   'from_country_id': this.getCurrency(),
  //   'currency_id': this.getFromCountry(),
  //   'import-file': '',
  //   // 'comment': this.getComment(),
  //   // 'number': this.getNumber(),
  // }
}

/**
 * Validations
 */

ZonePrice.prototype.firstValidationUser = function () {

  let validationItems = {
    deliveryService: this.getDeliveryService(),
    currency: this.getCurrency(),
    fromCountry: this.getFromCountry(),
    // useDefault: this.getUseDefault(),
    // marketplace: this.getMarketplace(),
    // number: this.getNumber(),

  }

  let validationOptions = {
    deliveryService: {type: ['empty']},
    currency: {type: ['empty']},
    fromCountry: {type: ['empty']},
    // number: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.File.firstValidation())
}
