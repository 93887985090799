<template>
  <div class="fragment">
    <template v-if="$store.getters.getOrderPrice.length > 0 && $store.getters.getOrderPriceLoading !== true">
      <div class="custom-row accounts-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20"
             v-for="(item, index) in $store.getters.getOrderPrice"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__label-status"
                         v-if="item.use_default"
                    >
                      <div class="case-cart__label-status-i active"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['orderPrice_UseDefault'])"></div>
                        {{$t('orderPrice_UseDefault.localization_value.value')}}
                      </div>
                    </div>
                    <div class="case-cart__title pt-2">
                      <span class="color-black pr-2">
                        <ValueHelper
                            :value="item.cost"
                            :costType="true"
                            :costCurrencyType="'dollar'"
                            :replaceValue="'$ 0'"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="removeItem(item.id, index)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <CaseCartButton
                      :value="$t('common_edit.localization_value.value')"
                      :ico="true"
                      :typeIco="'edit'"
                      @click.native="changeOrderPricePopup(true, item)"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getOrderPriceCommonList.next_page_url !== null && $store.getters.getOrderPrice.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextOrderPricePage}"
              :count="$store.getters.getOrderPriceCommonList.total - $store.getters.getOrderPriceForPage < $store.getters.getOrderPriceForPage ?
                  $store.getters.getOrderPriceCommonList.total - $store.getters.getOrderPriceForPage:
                  $store.getters.getOrderPriceForPage"
          />
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getOrderPriceLoading === false && $store.getters.getOrderPrice.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
    
    <OrderPricePopup
        v-if="isModalOrderPricePopup"
        :navTabs="navTabs"
        :modalOrderPriceId="modalOrderPriceId"
        :type="'edit'"
        @close="changeOrderPricePopup(false)"
        @reload="$emit('reload')"
    />


  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import OrderPricePopup from "../../../../popups/OrderPricePopup/OrderPricePopup";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "@/components/coreComponents/NoResult/NoResult";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "OrderPriceTable",

    components: {
      ValueHelper,
      NoResult,
      ShowMore,
      CaseCartButton,
      OrderPricePopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      navTabs: Object,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalOrderPricePopup: false,
        modalOrderPriceId: null,
        itemPrice: '',
      }
    },

    methods: {

      changeOrderPricePopup(val, item = null) {
        this.isModalOrderPricePopup = val
        this.modalOrderPriceId = item.id
      },

      closePopup() {
        // this.isModalProductsToWarehouse = false
      },

      removeItem(id, index){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrderPrice', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orderPrice = this.$store.getters.getOrderPrice
              orderPrice.splice(index, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },

      changeInfoPopup(val) {
        this.isModalInfoPopup = val
      }

    }

  }

</script>

<style scoped lang="scss">


</style>