<template>
  <ZonePriceTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :navTabs="navTabs"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @reload="reload"
  />
</template>

<script>
  import ZonePriceTableAdmin from "./ZonePriceTableAdmin/ZonePriceTableAdmin";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ZonePriceTable",
    components: {
      ZonePriceTableAdmin,
    },

    mixins: [routeFilter],



    data() {
      return {
        forPage: this.$store.getters.getZonePriceForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterId: '',

        countFilterParams: 0,
        filterGetParams: {},
        navTabs: {
          fedexPrague: {
            active: true,
            label: 'zonePrice_FedexPrague',
            name: 'fedexPrague',
          },
        }

      }
    },


    mounted() {


      this.filter()
    },

    methods: {


      deleteOrder() {

      },


      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )



        if (next) this.$store.commit('setNextZonePricePage', true)
        this.$store.dispatch('fetchZonePrice', url).then(() => {
          this.$store.commit('setNextZonePricePage', false)
        })

        this.checkCountFilter([])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if(this.navTabs.fedexPrague.active) {
          myQuery.where('CountryId', 56)
          myQuery.where('DeliveryServiceId',2)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },
    },


  }
</script>

<style scoped>

</style>