<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['iossNumbers_importIOSSNumber',])"></span>
        <div v-if="typeZip">
          {{$t('orderPrice_importPriceMask.localization_value.value')}}
        </div>
        <div v-else>
          {{$t('orderPrice_importPrice.localization_value.value')}}
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="import-transaction__content">

        <div class="import-transaction__row custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-if="typeZip"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_FromCountry'])"></span>
            <DefaultSelect
                :options="optionsCountry"
                :label="$t('orderPrice_FromCountry.localization_value.value')"
                v-bind:class="{'ui-no-valid': IP.validation.country}"
                :errorTxt="$t(`${IP.validationTranslate.country}.localization_value.value`)"
                :error="IP.validation.country"
                :selected="IP.data.country"
                :optionsLabel="'label'"
                @change="(val) => IP.data.country = val"
            />
          </div>
        </div>

        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col position-relative">
            <DropZone class="drop-zone-bg"
                      :parentFiles="IP.data.Files.data.files"
                      :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                      :maxCount="IP.data.Files.data.maxCountFiles - IP.data.Files.data.downloadFiles.length"
                      :maxSize="IP.data.Files.data.maxSizeFiles"
                      @changeImg="changeImg"
            />
            <span class="error-field ml-3" v-if="IP.data.Files.validation.files">
                {{$t('common_fileIsRequired.localization_value.value')}}
              </span>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="importPrice"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import DropZone from "@/components/coreComponents/DropZone/DropZone";
  import {ImportPrice} from "@/components/modules/OrderPriceModule/models/ImportPrice";
  import {countriesMixin} from "@/mixins/commonMixins/countriesMixin";
  import {ORDER_PRICE_ZONE_SKIPPING, ROYAL_MAIL_COMPANY, UNITED_KINGDOM_ID} from "@/staticData/staticVariables";

  export default {
    name: "ImportPricePopup",
    components: {
      DropZone,
      DefaultSelect,
      Modal,
      MainButton,
    },

    mixins: [countriesMixin],

    props: {
      typeZip: {
        type: Boolean,
        default: false
      },
      navTabs: Object,
    },

    data() {
      return {
        files: [],
        IP: new ImportPrice(),
        optionsCountry: [],
        optionsFromCountry: [],
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)

        this.optionsCountry = this.getCountriesForRegistration(countries)
        this.optionsFromCountry = this.getCountriesForRegistration(countries)
      })
    },

    methods: {
      changeImg(files, maxSizeError) {
        this.IP.data.Files.setFiles(files)

        this.IP.data.Files.setFilesMaxSizeError(maxSizeError)
      },

      importPrice() {
        if(this.typeZip && !this.IP.firstValidationUser()) return

        if(!this.IP.data.Files.firstValidation()) return

        let data = this.IP.prepareSave()
        let activeTab = this._.find(this.navTabs, {active: true})

        if(ROYAL_MAIL_COMPANY.name === activeTab.name) {
          data['from_country_id'] = UNITED_KINGDOM_ID
        }

        if (this.IP.data.Files.data.files.length > 0) {
          let reader = new FormData();
          reader.append("import-file", this._.first(this.IP.data.Files.data.files).file)
          reader.append("country_id", data.country_id)
          reader.append("from_country_id", data.from_country_id)
          reader.append("delivery_service_id", activeTab?.value)

          let typeDispatch = this.typeZip ? 'ImportPriceZip' : 'ImportPrice'

          /**
           * FOR ZONE_SKIPPING
           */
          if(ORDER_PRICE_ZONE_SKIPPING.name === activeTab.name) {
            reader = new FormData();
            reader.append("import-file", this._.first(this.IP.data.Files.data.files).file)
            typeDispatch = 'zoneSkipping'
          }

          this.$store.dispatch(typeDispatch, reader).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('close')
                this.$emit('reload')

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }

      },
    }
  }
</script>

<style scoped>

</style>
