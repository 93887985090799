<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator content-tabs--scrolling">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="scrolling-tabs" v-if="hasScrolling">
        <div class="scrolling-tabs-left hide" @click="scrollTabsLeft">❮❮</div>
        <div class="scrolling-tabs-right hide" @click="scrollTabsRight">❯❯</div>
      </div>

    </div>

    <div class="horiz-block mt-4 mb-4">
      <div class="horiz-block__inner d-flex justify-content-end">
        <template
            v-if="isOtherPrices">
          <div
              v-if="navTabs[POSTNL_SHIPPING_COMPANY.name].active"
              class="mt-2 mr-3"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_importPriceMask'])"></span>
            <TableLeftBlockBtn
                class="btn-left-block-i--link"
                :value="$t('orderPrice_importPriceMask.localization_value.value')"
                :type="'transfer'"
                @click.native="importPriceMask"
            />
          </div>
          <div class="mt-2 mr-auto"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_importPrice'])"></span>
            <TableLeftBlockBtn
                class="btn-left-block-i--link"
                :value="$t('orderPrice_importPrice.localization_value.value')"
                :type="'transfer'"
                @click.native="importPrice"
            />
          </div>
        </template>


        <div
            v-if="navTabs[DPD_SHIPPING_COMPANY.name].active || navTabs[POSTNL_SHIPPING_COMPANY.name].active || navTabs[GLOBAL24_SHIPPING_COMPANY.name].active"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['orderPrice_NewPrice'])"></span>
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="'+ ' + $t('orderPrice_NewPrice.localization_value.value')"
                      @click.native="changeOrderOtherPriceCreatePopup(true)"
          >
          </MainButton>
        </div>
        <div v-else-if="navTabs[APC_CAMBRIDGE.name].active"></div>
        <div v-else-if="navTabs[ROYAL_MAIL_COMPANY.name].active"></div>
        <div v-else-if="navTabs[ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name].active"></div>
        <template v-else>
          <div class="mr-3"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['common_print'])"></span>
            <MainButton class="white-space-nowrap scoped-btn brown"
                        :value="$t('common_print.localization_value.value')"
                        @click.native="$emit('print')"
            >
            </MainButton>
          </div>
          <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_NewPrice'])"></span>
            <MainButton class="white-space-nowrap scoped-btn"
                        :value="'+ ' + $t('orderPrice_NewPrice.localization_value.value')"
                        @click.native="changeOrderPriceCreatePopup(true)"
            >
            </MainButton>
          </div>
        </template>

      </div>
    </div>

    <OrderPricePopup
      v-if="isModalOrderPriceCreatePopup"
      :navTabs="navTabs"
      @close="changeOrderPriceCreatePopup(false)"
      @reload="$emit('reload')"
    />

    <OrderOtherPricePopup
      v-if="isModalOrderOtherPriceCreatePopup"
      :navTabs="navTabs"
      @close="changeOrderOtherPriceCreatePopup(false)"
      @reload="$emit('reload')"
    />

    <ImportPricePopup
        v-if="isModalImportPopup"
        :navTabs="navTabs"
        :typeZip="importTypeZip"
        @close="isModalImportPopup = false"
        @reload="$emit('reload')"
    />
  </div>
</template>



<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import OrderPricePopup from "../../../../popups/OrderPricePopup/OrderPricePopup";
  import OrderOtherPricePopup from "../../../../popups/OrderOtherPricePopup/OrderOtherPricePopup";
  import {
    APC_CAMBRIDGE,
    DPD_SHIPPING_COMPANY,
    GLOBAL24_SHIPPING_COMPANY, ORDER_PRICE_TYPES,
    POSTNL_SHIPPING_COMPANY, ROYAL_MAIL_COMPANY
  } from "@/staticData/staticVariables";
  import TableLeftBlockBtn from "@/components/coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";
  import ImportPricePopup from "@/components/modules/OrderPriceModule/popups/ImportPricePopup/ImportPricePopup";
  import {tabsScrollingMixin} from "@/mixins/globalMixins/tabsScrollingMixin";


  export default {
    name: "OrderPriceHead",
    components: {
      ImportPricePopup,
      TableLeftBlockBtn,
      MainButton,
      // SearchEngine,
      OrderPricePopup,
      OrderOtherPricePopup,
    },

    mixins: [tabsScrollingMixin],

    props: {
      navTabs: Object,
      isOtherPrices: Boolean,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
        input01: '',
        options: [{}],
        isModalOrderPriceCreatePopup: false,
        isModalOrderOtherPriceCreatePopup: false,
        isModalImportPopup: false,

        importTypeZip: false,

        DPD_SHIPPING_COMPANY: DPD_SHIPPING_COMPANY,
        POSTNL_SHIPPING_COMPANY: POSTNL_SHIPPING_COMPANY,
        GLOBAL24_SHIPPING_COMPANY: GLOBAL24_SHIPPING_COMPANY,
        APC_CAMBRIDGE: APC_CAMBRIDGE,
        ROYAL_MAIL_COMPANY: ROYAL_MAIL_COMPANY,
        ORDER_PRICE_TYPES: ORDER_PRICE_TYPES,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },


      changeOrderPriceCreatePopup(val){
        this.isModalOrderPriceCreatePopup = val
      },

      changeOrderOtherPriceCreatePopup(val){
        this.isModalOrderOtherPriceCreatePopup = val
      },

      importPrice() {
        this.importTypeZip = false
        this.isModalImportPopup = true
      },

      importPriceMask() {
        this.importTypeZip = true
        this.isModalImportPopup = true
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }

  .content-tabs {
    width: 100%;
  }

  .content-tabs__item {
    position: relative;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
</style>

