<template>
  <div class="head-left-block-btn btn-style"
       :class="{ 'disable-click' : disabled }"
  >
    <div class="head-left-block-btn__txt"
        :class="{
          'consolidation' : type === 'consolidation',
          'delete' : type === 'delete',
          'transfer' : type === 'transfer',
          'print' : type === 'print',
          'generate' : type === 'generate',
        }"
    >
      {{value}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "TableLeftBlockBtn",
    props: [
      'type',
      'value',
      'disabled'
    ]
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .head-left-block-btn{
    &__txt{
      position: relative;
      padding-left: 22px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $accent;

      @include for-1600 {
        font-size: 16px;
      }

      @include for-1120 {
        font-size: 14px;
      }

      &:before{
        content: "";
        position: absolute;
        left: 1px;
        top: 3px;
        display: block;
        width: 14px;
        height: 14px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.consolidation:before{
        background-image: url("../../../../assets/img/common/consolidation-icon.svg");
      }

      &.delete:before{
        background-image: url("../../../../assets/img/UI-group/delete-accent-icon.svg");
      }

      &.transfer:before{
        background-image: url("../../../../assets/img/common/transfer.svg");
        width: 16px;
        height: 16px;
      }

      &.print:before{
        background-image: url("../../../../assets/img/common/print-accent-icon.svg");
        width: 16px;
        height: 16px;
      }

      &.generate:before{
        background-image: url("../../../../assets/img/common/pdf-accent-icon.svg");
        width: 16px;
        height: 16px;
      }

    }
  }
</style>
