<template>
  <div class="fragment">
    <template v-if="$store.getters.getOrderPriceDeliveryServices.length > 0 && $store.getters.getOrderPriceDeliveryServicesLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'orderPrice_ID',
            'orderPrice_Created',
            'orderPrice_Country',
            'orderPrice_Price',
            'orderPrice_Weight',
            'orderPrice_FromCountry',
            'orderPrice_Zip',
            ])"></div>

        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th> {{$t('orderPrice_ID.localization_value.value')}}</th>
            <th> {{$t('orderPrice_Created.localization_value.value')}}</th>
            <th> {{$t('orderPrice_Country.localization_value.value')}}</th>
            <th> {{$t('orderPrice_Price.localization_value.value')}},$</th>
            <th> {{$t('orderPrice_Weight.localization_value.value')}}</th>
            <th> {{$t('orderPrice_FromCountry.localization_value.value')}}</th>
            <th width="100%" v-if="this.navTabs[POSTNL_SHIPPING_COMPANY.name].active"> {{$t('orderPrice_Zip.localization_value.value')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOrderPriceDeliveryServices" :key="index">
            <td>
              <span class="table-link">
                #{{item.id}}
              </span>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'country.currentTranslate.name'"
              />
            </td>
            <td>
              {{item.price}}
            </td>
            <td>
              {{parseFloat(item.max_weight).toFixed(2)}}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'from_country.currentTranslate.name'"
              />
            </td>
            <td v-if="navTabs[POSTNL_SHIPPING_COMPANY.name].active">
              <ValueHelper
                  :value="item"
                  :deep="'zip_mask'"
              />
            </td>
            <td align="right">
              <div
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_edit','common_delete',])"></div>
                <div class="table-right table-manage-list table-manage-list--small">

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                                 class="right"
                  >
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          @click.native="changeOrderPriceDeliveryServicesPopup(true, item)"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeItem(item.id)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrderPriceDeliveryServices"
                 :key="index"
            >

              <OrderPriceDeliveryServicesTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  @changeOrderPriceDeliveryServicesPopup="changeOrderPriceDeliveryServicesPopup(true, item)"
                  @removeItem="removeItem(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn" >
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getOrderPriceDeliveryServicesCommonList.next_page_url !== null && $store.getters.getOrderPriceDeliveryServices.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getOrderPriceDeliveryServicesCommonList.total - $store.getters.getOrderPriceDeliveryServicesForPage < $store.getters.getOrderPriceDeliveryServicesForPage ?
                  $store.getters.getOrderPriceDeliveryServicesCommonList.total - $store.getters.getOrderPriceDeliveryServicesForPage:
                  $store.getters.getOrderPriceDeliveryServicesForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getOrderPriceDeliveryServicesLoading === false && $store.getters.getOrderPriceDeliveryServices.length === 0 && !navTabs[ORDER_PRICE_TYPES.ORDER_PRICE_ZONE_SKIPPING.name].active">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
    
    <OrderOtherPricePopup
        v-if="isModalOrderPriceDeliveryServicesPopup"
        :navTabs="navTabs"
        :modalOrderPriceDeliveryServicesId="modalOrderPriceDeliveryServicesId"
        :type="'edit'"
        @close="changeOrderPriceDeliveryServicesPopup(false)"
        @reload="$emit('reload')"
    />


  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import OrderOtherPricePopup from "../../../../popups/OrderOtherPricePopup/OrderOtherPricePopup";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "@/components/coreComponents/NoResult/NoResult";
  import ManagerButton from "@/components/UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import {ORDER_PRICE_TYPES, POSTNL_SHIPPING_COMPANY} from "@/staticData/staticVariables";
  import OrderPriceDeliveryServicesTableMobile
    from "@/components/modules/OrderPriceModule/components/OrderPriceTable/OrderPriceTableAdmin/OrderOtherPriceTable/OrderPriceDeliveryServicesTableMobile/OrderPriceDeliveryServicesTableMobile";


  export default {
    name: "OrderOtherPriceTable",

    components: {
      OrderPriceDeliveryServicesTableMobile,
      ValueHelper,
      LinkButton,
      ManagerButton,
      NoResult,
      ShowMore,
      OrderOtherPricePopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      navTabs: Object,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalOrderPriceDeliveryServicesPopup: false,
        modalOrderPriceDeliveryServicesId: null,
        itemPrice: '',

        POSTNL_SHIPPING_COMPANY: POSTNL_SHIPPING_COMPANY,
        ORDER_PRICE_TYPES: ORDER_PRICE_TYPES,
      }
    },

    methods: {

      changeOrderPriceDeliveryServicesPopup(val, item = null) {
        this.isModalOrderPriceDeliveryServicesPopup = val
        this.modalOrderPriceDeliveryServicesId = item?.id
      },

      closePopup() {
        // this.isModalProductsToWarehouse = false
      },

      removeItem(id, index){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrderPriceDeliveryServices', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orderPrice = this.$store.getters.getOrderPriceDeliveryServices
              orderPrice.splice(index, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },

      changeInfoPopup(val) {
        this.isModalInfoPopup = val
      }

    }

  }

</script>

<style scoped lang="scss">


</style>