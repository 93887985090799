<template>
  <div>
    <OrderPriceTableHead
        :navTabs="navTabs"
        :isOtherPrices="isOtherPrices"
        :countFilterParams="countFilterParams"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @print="$emit('print')"
    />

    <div class="table-filter-wrap">
      <OrderOtherPriceTable
          v-if="isOtherPrices"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @deleteOrder="(id) => $emit('deleteOrder', id)"
      />

      <OrderPriceTable
          v-if="!isOtherPrices"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @print="$emit('print')"
          @deleteOrder="(id) => $emit('deleteOrder', id)"
      />
    </div>
  </div>
</template>

<script>


  import OrderPriceTableHead from "./OrderPriceHead/OrderPriceHead";
  import OrderPriceTable from "./OrderPriceTable/OrderPriceTable";
  import OrderOtherPriceTable from "./OrderOtherPriceTable/OrderOtherPriceTable";

  export default {
    name: "OrderPriceTableAdmin",

    components: {
      OrderPriceTableHead,
      OrderPriceTable,
      OrderOtherPriceTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
      isOtherPrices: Boolean,
    },

    data(){
      return{
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
        activeTab: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>
