<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <span
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['zonePrice_importPrices',])"></span>
        {{$t('zonePrice_importPrices.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="import-transaction__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['zonePrice_fileImport',])"></span>
        <div class="custom-popup__txt size-18px">
          {{$t('zonePrice_fileImport.localization_value.value')}}
        </div>
        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col position-relative">
            <DropZone class="drop-zone-bg"
                      :parentFiles="ZP.data.File.data.files"
                      @changeImg="changeImg"
                      :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                      :maxSize="'5'"
            />
            <div class="error-field ml-3" v-if="ZP.data.File.validation.files">
              {{$t('common_fileIsRequired.localization_value.value')}}
            </div>
          </div>
        </div>



        <div class="custom-row">
          <div class="custom-col custom-col--50 mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_ShippingCompanyTitle'])"></div>
            <DefaultSelect
                :options="optionsCountry"
                :label="$t('common_country.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': ZP.validation.fromCountry}"
                :error="ZP.validation.fromCountry"
                :errorTxt="$t(`${ZP.validationTranslate.fromCountry}.localization_value.value`)"
                :selected="ZP.data.fromCountry"
                @change="(val) => ZP.data.fromCountry = val"
            />
          </div>
          <div class="custom-col custom-col--50 mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['zonePrice_deliveryService'])"></div>
            <DefaultSelect
                :options="optionsDeliveryServices"
                :label="$t('zonePrice_deliveryService.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': ZP.validation.deliveryService}"
                :error="ZP.validation.deliveryService"
                :errorTxt="$t(`${ZP.validationTranslate.deliveryService}.localization_value.value`)"
                :selected="ZP.data.deliveryService"
                @change="(val) => ZP.data.deliveryService = val"
            />
          </div>
          <div class="custom-col custom-col--50 mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['zonePrice_currency'])"></div>
            <DefaultSelect
                :options="optionsCurrency"
                :label="$t('zonePrice_currency.localization_value.value')"
                :optionsLabel="'alpha_code'"
                v-bind:class="{'ui-no-valid': ZP.validation.currency}"
                :error="ZP.validation.currency"
                :errorTxt="$t(`${ZP.validationTranslate.currency}.localization_value.value`)"
                :selected="ZP.data.currency"
                @change="(val) => ZP.data.currency = val"
            />
          </div>
          <div class="custom-col custom-col--50 mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['zonePrice_warehouse'])"></div>
            <DefaultSelect
                :options="optionsWarehouses"
                :label="$t('zonePrice_warehouse.localization_value.value')"
                :optionsLabel="'sender_city'"
                :selected="ZP.data.warehouse"
                @change="(val) => ZP.data.warehouse = val"
            />
          </div>


        </div>
        
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="importProducts"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import {ZonePrice} from "@/components/modules/ZonePriceModule/models/ZonePrice";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ZonePriceImportPopup",
    components: {
      DefaultSelect,
      Modal,
      MainButton,
      DropZone,
    },

    data(){
      return {
        files: [],
        ZP: new ZonePrice(),

        optionsDeliveryServices: [],
        optionsCurrency: [],
        optionsCountry: [],
        optionsWarehouses: [],

      }
    },

    mounted() {
      this.$store.dispatch('getCreateZonePrice').then((response) => {
        this.optionsCurrency = this.getRespData(response).currencies
        this.optionsDeliveryServices = this.getRespData(response).delivery_services
        this.optionsCountry = this.getRespData(response).from_countries
      })
      this.$store.dispatch('fetchWarehouses', '?limit=100').then((response) => {
        this.optionsWarehouses = this.getRespPaginateData(response)
      })
    },

    methods: {

      changeImg(files, maxSizeError) {
        this.ZP.data.File.setFiles(files)

        this.ZP.data.File.setFilesMaxSizeError(maxSizeError)
      },

      importProducts() {

        if(!this.ZP.firstValidationUser()) return

        let data = this.ZP.prepareSave()

        this.$store.dispatch('importZonePrice', data).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

      },
    }
  }

</script>

<style scoped>
  .size-18px{
    font-size: 18px;
  }

  @media(max-width: 1150px){
    .size-18px{
      font-size: 16px;
    }
  }
</style>
