<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div v-if="type === 'create'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderPrice_NewPricePopup'])"></span>
        {{$t('orderPrice_NewPricePopup.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderPrice_EditPricePopup'])"></span>
        {{$t('orderPrice_EditPricePopup.localization_value.value')}}
      </div>
      <div class="site-link brown text-decoration-underline mb-3 mt-2">
        <div v-if="isAdmin && OOP.data.historyOfChanged"
             class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
               @click="historyOfChangesFunc('orderPriceOther', OOP.data.historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_EditPricePopup'])"></span>
            <InputSum
                :icoType="'dollar'"
                :label="$t('orderPrice_Price.localization_value.value')"
                v-bind:class="{'ui-no-valid': OOP.validation.price}"
                :errorTxt="$t(`${OOP.validationTranslate.price}.localization_value.value`)"
                :error="OOP.validation.price"
                v-model="OOP.data.price"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_Weight'])"></span>
            <DefaultInput
                :label="$t('orderPrice_Weight.localization_value.value')"
                v-bind:class="{'ui-no-valid': OOP.validation.weight}"
                :errorTxt="$t(`${OOP.validationTranslate.weight}.localization_value.value`)"
                :error="OOP.validation.weight"
                v-model="OOP.data.weight"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_Country'])"></span>
            <DefaultSelect
                :options="optionsCountry"
                :label="$t('orderPrice_Country.localization_value.value')"
                v-bind:class="{'ui-no-valid': OOP.validation.country}"
                :errorTxt="$t(`${OOP.validationTranslate.country}.localization_value.value`)"
                :error="OOP.validation.country"
                :selected="OOP.data.country"
                :optionsLabel="'label'"
                @change="(val) => OOP.data.country = val"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_FromCountry'])"></span>
            <DefaultSelect
                :options="optionsFromCountry"
                :label="$t('orderPrice_FromCountry.localization_value.value')"
                v-bind:class="{'ui-no-valid': OOP.validation.fromCountry}"
                :errorTxt="$t(`${OOP.validationTranslate.fromCountry}.localization_value.value`)"
                :error="OOP.validation.fromCountry"
                :selected="OOP.data.fromCountry"
                :optionsLabel="'label'"
                @change="(val) => OOP.data.fromCountry = val"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-if="this.navTabs[POSTNL_SHIPPING_COMPANY.name].active"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_Zip'])"></span>
            <DefaultInput
                :label="$t('orderPrice_Zip.localization_value.value')"
                v-bind:class="{'ui-no-valid': OOP.validation.zip}"
                :errorTxt="$t(`${OOP.validationTranslate.zip}.localization_value.value`)"
                :error="OOP.validation.zip"
                v-model="OOP.data.zip"
            />
          </div>

<!--          <div class="custom-col"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['orderPrice_UseDefault'])"></span>-->
<!--            <DefaultCheckbox-->
<!--              :label="$t('orderPrice_UseDefault.localization_value.value')"-->
<!--              v-model="OP.data.useDefault"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import {OrderOtherPrice} from "@/components/modules/OrderPriceModule/models/OrderOtherPrice";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {countriesMixin} from "@/mixins/commonMixins/countriesMixin";
  import {POSTNL_SHIPPING_COMPANY} from "@/staticData/staticVariables";
  // import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "OrderPriceDeliveryServicesCreatePopup",
    components: {
      DefaultSelect,
      DefaultInput,
      Modal,
      MainButton,
      InputSum,
      // DefaultCheckbox,
    },

    mixins: [countriesMixin],

    props: {
      type: {
        type: String,
        default: 'create'
      },
      modalOrderPriceDeliveryServicesId: Number,
      navTabs: Object,
    },

    data() {
      return {
        OOP: new OrderOtherPrice(),

        POSTNL_SHIPPING_COMPANY: POSTNL_SHIPPING_COMPANY,

        optionsCountry: [],
        optionsFromCountry: [],
      }
    },

    mounted() {

      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)

        this.optionsCountry = this.getCountriesForRegistration(countries)
        this.optionsFromCountry = this.getCountriesForRegistration(countries)
      })

      if (this.type !== 'create') {
        this.$store.dispatch('getOrderPriceDeliveryServices', this.modalOrderPriceDeliveryServicesId).then(response => {
          let data = this.getRespData(response)
          this.OOP.setItem(data)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if(!this.OOP.firstValidationUser()) return

        let data = this.OOP.prepareSave()

        let activeTab = this._.find(this.navTabs, {active: true})

        data.delivery_service_id = activeTab.value

        let typeDispatch = 'createOrderPriceDeliveryServices'

        if (this.type !== 'create') {
          typeDispatch = 'updateOrderPriceDeliveryServices'
          data = {
            data: data,
            id: this.modalOrderPriceDeliveryServicesId
          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(this.OOP.data.useDefault){
                this.$store.dispatch('setOrderPriceDeliveryServices', this.modalOrderPriceDeliveryServicesId).then(() => {
                  if (this.type === 'create') {
                    this.openNotify('success', 'common_notificationRecordCreated')
                  } else {
                    this.openNotify('success', 'common_notificationRecordChanged')
                  }
                  this.$emit('close')
                  this.$emit('reload')
                })
                return
              }

              if (this.type === 'create') {
                this.openNotify('success', 'common_notificationRecordCreated')
              } else {
                this.openNotify('success', 'common_notificationRecordChanged')
              }
              this.$emit('close')
              this.$emit('reload')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
