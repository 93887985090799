<template>
  <div>
    <ZonePriceTableHead
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
      <ZonePriceTable
          v-if="!isOtherPrices"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @deleteOrder="(id) => $emit('deleteOrder', id)"
      />
    </div>
  </div>
</template>

<script>


  import ZonePriceTableHead from "./ZonePriceHead/ZonePriceHead";
  import ZonePriceTable from "./ZonePriceTable/ZonePriceTable";

  export default {
    name: "ZonePriceTableAdmin",

    components: {
      ZonePriceTableHead,
      ZonePriceTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
      isOtherPrices: Boolean,
    },

    data(){
      return{

      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
