import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function ImportPrice() {

  this.checkValid = checkValid

  this.data = {
    country: '',
    Files: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
  }

  this.validation = {
    country: false,
  }

  this.validationTranslate = {
    country: '',
  }

  this.validationTxt = {
    country: false,
  }

  /**
   * Getters
   */
  this.getCountry = () => {
    return this.data.country
  }

  /**
   * Setters
   */
  this.setCountry = (val) => {
    this.data.country = val
  }
}

/**
 * Global Setters
 */

ImportPrice.prototype.setItem = function () {

}

/**
 * Prepare Data
 */

ImportPrice.prototype.prepareSave = function () {
  return {
    'from_country_id': '220',
    'country_id': this.getCountry()?.id,
  }
}

/**
 * Validations
 */

ImportPrice.prototype.firstValidationUser = function () {

  let validationItems = {
    country: this.getCountry()?.id,

  }

  let validationOptions = {
    country: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
