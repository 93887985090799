import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function OrderPrice() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    price: '',
    useDefault: false,
    historyOfChanged: null,

    cmPerimeterFrom: '',
    cmPerimeterTo: '',
    incPerimeterFrom: '',
    incPerimeterTo: '',
    // marketplace: '',
    // comment: '',
    // number: '',
  }

  this.validation = {
    id: false,
    price: false,
    useDefault: false,
    cmPerimeterFrom: false,
    cmPerimeterTo: false,
    incPerimeterFrom: false,
    incPerimeterTo: false,
  }

  this.validationTranslate = {
    id: '',
    price: '',
    useDefault: '',
    cmPerimeterFrom: '',
    cmPerimeterTo: '',
    incPerimeterFrom: '',
    incPerimeterTo: '',
  }

  this.validationTxt = {
    id: false,
    price: false,
    useDefault: false,
    cmPerimeterFrom: false,
    cmPerimeterTo: false,
    incPerimeterFrom: false,
    incPerimeterTo: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getUseDefault = () => {
    return this.data.useDefault
  }
  this.getHistoryOfChanged = () => {
    return this.data.historyOfChanged
  }
  this.getCmPerimeterFrom = () => {
    return this.data.cmPerimeterFrom
  }
  this.getCmPerimeterTo = () => {
    return this.data.cmPerimeterTo
  }
  this.getIncPerimeterFrom = () => {
    return this.data.incPerimeterFrom
  }
  this.getIncPerimeterTo = () => {
    return this.data.incPerimeterTo
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setUseDefault = (val) => {
    this.data.useDefault = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
  this.setCmPerimeterFrom = (val) => {
    this.data.cmPerimeterFrom = val
  }
  this.setCmPerimeterTo = (val) => {
    this.data.cmPerimeterTo = val
  }
  this.setIncPerimeterFrom = (val) => {
    this.data.incPerimeterFrom = val
  }
  this.setIncPerimeterTo = (val) => {
    this.data.incPerimeterTo = val
  }
}

/**
 * Global Setters
 */

OrderPrice.prototype.setItem = function (item) {

  this.setId(item?.id)
  this.setPrice(parseFloat(item?.cost).toFixed(2))
  this.setUseDefault(item?.use_default)
  this.setHistoryOfChanged(item?.activityUUID)

  if(item?.imperial_perimeter_interval) {
    this.setIncPerimeterFrom(parseFloat(item?.imperial_perimeter_interval?.min).toFixed(2))
    this.setIncPerimeterTo(parseFloat(item?.imperial_perimeter_interval?.max).toFixed(2))
  }

  if(item?.metric_perimeter_interval) {
    this.setCmPerimeterFrom(parseFloat(item?.metric_perimeter_interval?.min).toFixed(2))
    this.setCmPerimeterTo(parseFloat(item?.metric_perimeter_interval?.max).toFixed(2))
  }
}

/**
 * Prepare Data
 */

OrderPrice.prototype.prepareSave = function () {
  return {
    'cost': this.getPrice(),
    'use_default': this.getUseDefault(),

    'metric_perimeter_interval': {
      'min': this.getCmPerimeterFrom(),
      'max': this.getCmPerimeterTo(),
    },

    'imperial_perimeter_interval': {
      'min': this.getIncPerimeterFrom(),
      'max': this.getIncPerimeterTo(),
    },

    // 'marketplace': this.getMarketplace(),
    // 'comment': this.getComment(),
    // 'number': this.getNumber(),
  }
}

/**
 * Validations
 */

OrderPrice.prototype.firstValidationUser = function () {

  let validationItems = {
    price: this.getPrice(),
    // useDefault: this.getUseDefault(),
    // marketplace: this.getMarketplace(),
    // number: this.getNumber(),

  }

  let validationOptions = {
    price: {type: ['not-zero','empty']},
    // useDefault: {type: ['empty']},
    // marketplace: {type: ['empty']},
    // number: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
