<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getZonePrice.length > 0 && $store.getters.getZonePriceLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'forbiddenGoods_ID',
            'common_date',
            'forbiddenGoods_Transaction',
            'forbiddenGoods_Store',
            'forbiddenGoods_ZonePriceKeyword',
            'common_manage',
            'forbiddenGoods_ToBalance',
            'forbiddenGoods_Refund',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('zonePrice_Id.localization_value.value') }}</th>
            <th>{{ $t('zonePrice_date.localization_value.value') }}</th>
            <th>{{ $t('zonePrice_currency.localization_value.value') }}</th>
            <th>{{ $t('zonePrice_deliveryService.localization_value.value') }}</th>
            <th>{{ $t('common_country.localization_value.value') }}</th>
            <th>{{ $t('zonePrice_weight.localization_value.value') }}</th>
            <th>{{ $t('zonePrice_price.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getZonePrice" :key="index">
            <td>
              #{{ item.id }}
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'currency.alpha_code'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'delivery_service.name'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'country.name'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'weight_kg'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'price'"
              />
            </td>
            <td class="hide-1400"></td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getZonePrice"
                 :key="index">
              <ZonePriceTableMobile
                  :item="item"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getZonePriceCommonList.next_page_url !== null && $store.getters.getZonePrice.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getZonePriceCommonList.total - $store.getters.getZonePriceForPage < $store.getters.getZonePriceForPage ?
                    $store.getters.getZonePriceCommonList.total - $store.getters.getZonePriceForPage:
                    $store.getters.getZonePriceForPage"
            />
          </div>
        </div>


      </div>
    </template>

    <template v-if="$store.getters.getZonePriceLoading === false && $store.getters.getZonePrice.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
    
<!--    <ZonePricePopup-->
<!--        v-if="isModalZonePricePopup"-->
<!--        :navTabs="navTabs"-->
<!--        :modalZonePriceId="modalZonePriceId"-->
<!--        :type="'edit'"-->
<!--        @close="changeZonePricePopup(false)"-->
<!--        @reload="$emit('reload')"-->
<!--    />-->


  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import ZonePricePopup from "../../../../popups/ZonePricePopup/ZonePricePopup";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "@/components/coreComponents/NoResult/NoResult";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import ZonePriceTableMobile
    from "@/components/modules/ZonePriceModule/components/ZonePriceTable/ZonePriceTableAdmin/ZonePriceTable/ZonePriceTableMobile/ZonePriceTableMobile";


  export default {
    name: "ZonePriceTable",

    components: {
      ZonePriceTableMobile,
      ValueHelper,
      NoResult,
      ShowMore,
      // ZonePricePopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      navTabs: Object,
    },


    data() {
      return {

      }
    },

    methods: {

      changeZonePricePopup(val, item = null) {
        this.isModalZonePricePopup = val
        this.modalZonePriceId = item.id
      },

      closePopup() {
        // this.isModalProductsToWarehouse = false
      },

      removeItem(id, index){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteZonePrice', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orderPrice = this.$store.getters.getZonePrice
              orderPrice.splice(index, 1)

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      },

      changeInfoPopup(val) {
        this.isModalInfoPopup = val
      }

    }

  }

</script>

<style scoped lang="scss">


</style>