<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div v-if="type === 'create'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderPrice_NewPricePopup'])"></span>
        {{$t('orderPrice_NewPricePopup.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderPrice_EditPricePopup'])"></span>
        {{$t('orderPrice_EditPricePopup.localization_value.value')}}
      </div>
      <div class="site-link brown text-decoration-underline mb-3 mt-2">
        <div v-if="isAdmin && OP.data.historyOfChanged"
             class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
               @click="historyOfChangesFunc('orderPrice', OP.data.historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_Price'])"></span>
            <InputSum
                :icoType="'dollar'"
                :label="$t('orderPrice_Price.localization_value.value')"
                v-bind:class="{'ui-no-valid': OP.validation.price}"
                :errorTxt="$t(`${OP.validationTranslate.price}.localization_value.value`)"
                :error="OP.validation.price"
                v-model="OP.data.price"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100 mb-0"></div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderSize_SizeCmPerimeterFrom'])"></span>
            <InputSum
                :icoType="'cm'"
                :label="$t('orderSize_SizeCmPerimeterFrom.localization_value.value')"
                v-bind:class="{'ui-no-valid': OP.validation.cmPerimeterFrom}"
                :errorTxt="$t(`${OP.validationTranslate.cmPerimeterFrom}.localization_value.value`)"
                :error="OP.validation.cmPerimeterFrom"
                v-model="OP.data.cmPerimeterFrom"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderSize_SizeCmPerimeterTo'])"></span>
            <InputSum
                :icoType="'cm'"
                :label="$t('orderSize_SizeCmPerimeterTo.localization_value.value')"
                v-bind:class="{'ui-no-valid': OP.validation.cmPerimeterTo}"
                :errorTxt="$t(`${OP.validationTranslate.cmPerimeterTo}.localization_value.value`)"
                :error="OP.validation.cmPerimeterTo"
                v-model="OP.data.cmPerimeterTo"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderSize_SizeIncPerimeterFrom'])"></span>
            <InputSum
                :icoType="'inc'"
                :label="$t('orderSize_SizeIncPerimeterFrom.localization_value.value')"
                v-bind:class="{'ui-no-valid': OP.validation.incPerimeterFrom}"
                :errorTxt="$t(`${OP.validationTranslate.incPerimeterFrom}.localization_value.value`)"
                :error="OP.validation.incPerimeterFrom"
                v-model="OP.data.incPerimeterFrom"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderSize_SizeIncPerimeterTo'])"></span>
            <InputSum
                :icoType="'inc'"
                :label="$t('orderSize_SizeIncPerimeterTo.localization_value.value')"
                v-bind:class="{'ui-no-valid': OP.validation.incPerimeterTo}"
                :errorTxt="$t(`${OP.validationTranslate.incPerimeterTo}.localization_value.value`)"
                :error="OP.validation.incPerimeterTo"
                v-model="OP.data.incPerimeterTo"
            />
          </div>
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderPrice_UseDefault'])"></span>
            <DefaultCheckbox
              :label="$t('orderPrice_UseDefault.localization_value.value')"
              v-model="OP.data.useDefault"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {OrderPrice} from "@/components/modules/OrderPriceModule/models/OrderPrice";

  export default {
    name: "OrderPriceCreatePopup",
    components: {
      Modal,
      MainButton,
      InputSum,
      DefaultCheckbox,
    },

    props: {
      type: {
        type: String,
        default: 'create'
      },
      modalOrderPriceId: Number,
      navTabs: Object,
    },

    data() {
      return {
        OP: new OrderPrice(),
      }
    },

    mounted() {
      if (this.type !== 'create') {
        this.$store.dispatch('getOrderPrice', this.modalOrderPriceId).then(response => {
          let data = this.getRespData(response)
          this.OP.setItem(data)
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      save() {
        if(!this.OP.firstValidationUser()) return

        let data = this.OP.prepareSave()

        let activeTab = this._.find(this.navTabs, {active: true})
        data.fee_type = activeTab.value

        let typeDispatch = 'createOrderPrice'

        if (this.type !== 'create') {
          typeDispatch = 'updateOrderPrice'
          data = {
            data: data,
            id: this.modalOrderPriceId
          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(this.OP.data.useDefault){
                this.$store.dispatch('setOrderPrice', this.modalOrderPriceId).then(() => {
                  if (this.type === 'create') {
                    this.openNotify('success', 'common_notificationRecordCreated')
                  } else {
                    this.openNotify('success', 'common_notificationRecordChanged')
                  }
                  this.$emit('close')
                  this.$emit('reload')
                })
                return
              }

              if (this.type === 'create') {
                this.openNotify('success', 'common_notificationRecordCreated')
              } else {
                this.openNotify('success', 'common_notificationRecordChanged')
              }
              this.$emit('close')
              this.$emit('reload')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
