import { render, staticRenderFns } from "./OrderPriceTableAdmin.vue?vue&type=template&id=736136aa&scoped=true"
import script from "./OrderPriceTableAdmin.vue?vue&type=script&lang=js"
export * from "./OrderPriceTableAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736136aa",
  null
  
)

export default component.exports