<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" style="left: -18px; top: 0;" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
      </div>
      <div class="btn-right-block">
        <div class="content-top-line-wrap ml-2" style="position: relative;"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_import',
        ])"></span>
          <TableGeadButton
              :value="$t('common_import.localization_value.value')"
              :ico="'import'"
              @click.native="isModalImportPopup = true"
          />
        </div>

      </div>
    </div>


    <ZonePriceImportPopup
        v-if="isModalImportPopup"
        @close="isModalImportPopup = false"
        @reload="$emit('reload')"
    />
  </div>
</template>



<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import TableLeftBlockBtn from "@/components/coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";


  import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import ZonePriceImportPopup
    from "@/components/modules/ZonePriceModule/popups/ZonePriceImportPopup/ZonePriceImportPopup";
  export default {
    name: "ZonePriceHead",
    components: {
      ZonePriceImportPopup,
      TableGeadButton
      // TableLeftBlockBtn,
      // MainButton,
    },

    props: {
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        isModalImportPopup: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },



    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
</style>

